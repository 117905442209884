/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SavedReports
// ====================================================

export interface SavedReports_savedReports_savedReport_reportInput_GetInsightsReportInputPayload {}

export interface SavedReports_savedReports_savedReport_reportInput_GetActivityReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  dateBy: DateBy
}

export interface SavedReports_savedReports_savedReport_reportInput_GetAppointmentsReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  appointmentsGroupBy: AppointmentsGroupBy
  appointmentsReportTab: AppointmentsReportTab
  sources: string[] | null // Array of sources
  agentIds: number[] | null // Array of agents
}

export interface SavedReports_savedReports_savedReport_reportInput_GetAdSpendReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  adType: AdType
}

export interface SavedReports_savedReports_savedReport_reportInput_GetAIVoiceReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetAIChatReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetAISmsReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetBoxscoreReportInputPayload {
  startDate: string
  endDate: string
  propertyId: number
  agentIds: number[] | null
  notAProspect: boolean
  prospectsBy: ProspectsBy
}

export interface SavedReports_savedReports_savedReport_reportInput_GetCallsReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  callerType: CallerType
}

export interface SavedReports_savedReports_savedReport_reportInput_GetConversionsReportInputPayload_attributeInput {
  attributeKey: string
  attributeValue: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetConversionsReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  conversionsGroupBy: ConversionsGroupBy
  dateBy: DateBy
  filterByCustomAttribute: boolean
  sources: string[] | null // Array of sources
  contactTypes: string[] | null // Array of contact types
  agentIds: number[] | null // Array of agent IDs
  attributeInput: SavedReports_savedReports_savedReport_reportInput_GetConversionsReportInputPayload_attributeInput | null // Selected custom attribute
}

export interface SavedReports_savedReports_savedReport_reportInput_GetCraigslistReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetDemographicsReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetDripCampaignReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  dripCampaignStatus: DripCampaignStatus | null // Status of campaign
  triggerType: DripCampaignTriggerType[] | null // Drip Campaign trigger types
}

export interface SavedReports_savedReports_savedReport_reportInput_GetIdVerificationReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  scanSource: string | null
}

export interface SavedReports_savedReports_savedReport_reportInput_GetIntentsReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetLossReasonsReportInputPayload {
  startDate: string
  endDate: string
  propertyIds: number[] // Array of property IDs
  agentIds: number[] | null // Array of agent IDs
  sources: string[] | null
  contacts: string[] | null
}

export interface SavedReports_savedReports_savedReport_reportInput_GetMultiTouchAttributionReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetOccupancyReportInputPayload {
  propertyIds: number[] // Array of property IDs
}

export interface SavedReports_savedReports_savedReport_reportInput_GetQualificationReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetResponseTimeReportInputPayload_attributeInput {
  attributeKey: string
  attributeValue: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetResponseTimeReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  groupBy: GroupBy
  filterByCustomAttribute: boolean
  sources: string[] | null // Array of sources
  contactTypes: string[] | null // Array of contact types
  agentIds: number[] | null // Array of agent IDs
  attributeInput: SavedReports_savedReports_savedReport_reportInput_GetResponseTimeReportInputPayload_attributeInput | null // Selected custom attribute
}

export interface SavedReports_savedReports_savedReport_reportInput_GetTourEngagementReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  tourEngagementReportTab: TourEngagementTab | null // Report Tab Name
}

export interface SavedReports_savedReports_savedReport_reportInput_GetTourTypeReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
}

export interface SavedReports_savedReports_savedReport_reportInput_GetEngagementReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  agentIds: number[] | null // Array of agent IDs
}

export interface SavedReports_savedReports_savedReport_reportInput_AttributionsReportInputPayload {
  propertyIds: number[] // Array of property IDs
  startDate: string
  endDate: string
  sourceNames: string[]
  viewAs: AttributionsViewAs
}

export type SavedReports_savedReports_savedReport_reportInput =
  | SavedReports_savedReports_savedReport_reportInput_GetInsightsReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetActivityReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetAppointmentsReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetAdSpendReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetAIVoiceReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetAIChatReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetAISmsReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetBoxscoreReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetCallsReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetConversionsReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetCraigslistReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetDemographicsReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetDripCampaignReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetIdVerificationReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetIntentsReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetLossReasonsReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetMultiTouchAttributionReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetOccupancyReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetQualificationReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetResponseTimeReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetTourEngagementReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetTourTypeReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_GetEngagementReportInputPayload
  | SavedReports_savedReports_savedReport_reportInput_AttributionsReportInputPayload

export interface SavedReports_savedReports_savedReport {
  savedReportId: number
  savedReportName: string | null
  reportType: string
  enabled: boolean
  createdByUserId: number
  createdOn: any
  reportInput: SavedReports_savedReports_savedReport_reportInput
}

export interface SavedReports_savedReports_subscription_shareInput {
  recipients: string[]
  subject: string | null
  includePdf: boolean | null
  includeCsv: boolean | null
}

export interface SavedReports_savedReports_subscription {
  subscriptionId: number
  frequency: ScheduleFrequency
  dayOfWeek: DayOfWeek | null
  dayOfMonth: number | null
  shareInput: SavedReports_savedReports_subscription_shareInput
}

export interface SavedReports_savedReports {
  savedReport: SavedReports_savedReports_savedReport
  subscription: SavedReports_savedReports_subscription | null
}

export interface SavedReports {
  savedReports: SavedReports_savedReports[] | null // Get a user's saved reports
}

export interface SavedReportsVariables {
  input: SavedReportsInput
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteScheduled
// ====================================================

export interface DeleteScheduled_deleteScheduledReport {
  subscriptionId: number
}

export interface DeleteScheduled {
  deleteScheduledReport: DeleteScheduled_deleteScheduledReport | null // Remove a scheduled report
}

export interface DeleteScheduledVariables {
  scheduledReportId: number
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSaved
// ====================================================

export interface DeleteSaved_deleteSavedReport {
  savedReportId: number
}

export interface DeleteSaved {
  deleteSavedReport: DeleteSaved_deleteSavedReport | null // Remove a saved report
}

export interface DeleteSavedVariables {
  savedReportId: number
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateScheduled
// ====================================================

export interface UpdateScheduled_updateScheduledReport {
  subscriptionId: number
}

export interface UpdateScheduled {
  updateScheduledReport: UpdateScheduled_updateScheduledReport | null // Update a scheduled report
}

export interface UpdateScheduledVariables {
  input: UpdateScheduleInput
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddScheduled
// ====================================================

export interface AddScheduled_addScheduledReport {
  subscriptionId: number
}

export interface AddScheduled {
  addScheduledReport: AddScheduled_addScheduledReport | null // Schedule a report
}

export interface AddScheduledVariables {
  input: ScheduleInput
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

// Scheduled filter types for savedReports
export enum Scheduled {
  ALL = 'ALL',
  NOT_SCHEDULED = 'NOT_SCHEDULED',
  SCHEDULED = 'SCHEDULED',
}

// DateBy filter types for Conversions Report
export enum DateBy {
  CREATED = 'CREATED',
  EVENT = 'EVENT',
}

// Group By filter types for Appointments Report
export enum AppointmentsGroupBy {
  PROPERTY = 'PROPERTY',
  SOURCE = 'SOURCE',
}

// Report tab of the Appointments Report
export enum AppointmentsReportTab {
  PAST = 'PAST',
  SCHEDULED = 'SCHEDULED',
}

// Ad Type filter types for Ad Spend Report
export enum AdType {
  ALL = 'ALL',
  NON_PAID = 'NON_PAID',
  PAID = 'PAID',
}

// ProspectsBy filter for Boxscore Report
export enum ProspectsBy {
  CREATE = 'CREATE',
  EVENT = 'EVENT',
}

// CallerType filter for Calls Report
export enum CallerType {
  ALL = 'ALL',
  FIRST_AND_RETURN_CALL = 'FIRST_AND_RETURN_CALL',
  FIRST_TIME = 'FIRST_TIME',
  RESIDENT = 'RESIDENT',
  RETURN_CALL = 'RETURN_CALL',
}

// Group By filter types for Conversions report
export enum ConversionsGroupBy {
  AGENT = 'AGENT',
  CONTACT = 'CONTACT',
  CUSTOM_ATTRIBUTE = 'CUSTOM_ATTRIBUTE',
  PROPERTY = 'PROPERTY',
  SOURCE = 'SOURCE',
  WEEK = 'WEEK',
}

// DripCampaignStatus filter for Drip Campaign Report
export enum DripCampaignStatus {
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
  LIVE = 'LIVE',
}

// DripCampaignTriggerType filter for Drip Campaign Report
export enum DripCampaignTriggerType {
  CANCELLED_MANAGER = 'CANCELLED_MANAGER',
  CANCELLED_RENTER = 'CANCELLED_RENTER',
  CONFIGURABLE_STALE = 'CONFIGURABLE_STALE',
  NO_SHOW = 'NO_SHOW',
  POST_APPOINTMENT_MESSAGE = 'POST_APPOINTMENT_MESSAGE',
  STALE_PROSPECT = 'STALE_PROSPECT',
}

// Group By filter types
export enum GroupBy {
  AGENT = 'AGENT',
  CONTACT = 'CONTACT',
  CUSTOM_ATTRIBUTE = 'CUSTOM_ATTRIBUTE',
  PROPERTY = 'PROPERTY',
  SOURCE = 'SOURCE',
}

// Tour Engagement Report Tab
export enum TourEngagementTab {
  ACTIVITY = 'ACTIVITY',
  LOSS_REASONS = 'LOSS_REASONS',
  NEXT_STEPS = 'NEXT_STEPS',
}

// View Attributions report at Property or Source level
export enum AttributionsViewAs {
  PROPERTY = 'PROPERTY',
  SOURCE = 'SOURCE',
}

// Frequency types for scheduling reports
export enum ScheduleFrequency {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

// Days of the week
export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}

// null
export interface SavedReportsInput {
  scheduled: Scheduled
  AI: boolean
  Activity: boolean
  AdSpend: boolean
  Appointments: boolean
  Boxscore: boolean
  CallLog: boolean
  CallTranscripts: boolean
  Calls: boolean
  Conversions: boolean
  Craigslist: boolean
  Demographics: boolean
  DripCampaign: boolean
  Engagement: boolean
  IdVerification: boolean
  Insights: boolean
  Intents: boolean
  LossReasons: boolean
  MultiTouchAttribution: boolean
  Occupancy: boolean
  Qualification: boolean
  ResponseTime: boolean
  TourEngagement: boolean
  TourType: boolean
  AiVoice: boolean
  AiChat: boolean
  AiSms: boolean
  Attributions: boolean
}

// null
export interface UpdateScheduleInput {
  subscriptionId: number
  frequency?: ScheduleFrequency | null
  dayOfWeek?: DayOfWeek | null
  dayOfMonth?: number | null
  shareInput?: UpdateShareInput | null
}

// null
export interface UpdateShareInput {
  recipients?: string[] | null
  subject?: string | null
  includePdf?: boolean | null
  includeCsv?: boolean | null
}

// null
export interface ScheduleInput {
  savedReportId: number
  frequency: ScheduleFrequency
  dayOfWeek?: DayOfWeek | null
  dayOfMonth?: number | null
  shareInput: BaseShareInput
}

// null
export interface BaseShareInput {
  recipients: string[]
  subject?: string | null
  includePdf?: boolean | null
  includeCsv?: boolean | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
