import React from 'react'
import CircularProgress from 'Components/CircularProgress'
import { DialogContent, Dialog } from 'Components/Dialog'

// styles
import useStyles from './styles'

export const Loader: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root__container}>
      <div className={classes.root__alignment_container}>
        <div className={classes.root__alignment_container_child}>
          <CircularProgress />
        </div>
      </div>
    </div>
  )
}

export const LoaderChildren: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <Dialog open={true}>
        <DialogContent className={classes.root}>
          <div className={classes.root__alignment_container}>
            <div className={classes.root__alignment_container_child}>
              <CircularProgress />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export const LoaderFallback: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root__loader_fallback}>
      <div className={classes.root__container}>
        <div className={classes.root__alignment_container}>
          <div className={classes.root__alignment_container_child}>
            <CircularProgress />
          </div>
        </div>
      </div>
    </div>
  )
}
