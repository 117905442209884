const config: Config = {
  ENV: process.env.REACT_APP_ENV as string,
  ANALYTICS_GRAPHQL_API: process.env.REACT_APP_ANALYTICS_GRAPHQL_API as string,
  ANALYTICS_API: process.env.REACT_APP_ANALYTICS_API as string,
  ANALYTICS_WEB: process.env.REACT_APP_ANALYTICS_WEB as string,
  CRM_API: process.env.REACT_APP_CRM_API as string,
  CRM_WEB: process.env.REACT_APP_CRM_WEB as string,
  ADMIN_WEB: process.env.REACT_APP_ADMIN_WEB as string,
  SYNDICATION_AUTH_API: process.env.REACT_APP_SYNDICATION_AUTH_API as string,
  TOKEN_ACCESS: process.env.REACT_APP_TOKEN_ACCESS as string,
  TOKEN_REFRESH: process.env.REACT_APP_TOKEN_REFRESH as string,
  TOKEN_REFRESH_HOURS: parseInt(process.env.REACT_APP_TOKEN_REFRESH_HOURS as string) as number,
  SESSION_EXPIRE: parseInt(process.env.REACT_APP_SESSION_EXPIRE as string) as number,
  CALL_TIMEOUT: parseInt(process.env.REACT_APP_CALL_TIMEOUT as string) as number,
  LAUNCH_DARKLY_CLIENTSIDE_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENTSIDE_ID as string,
  OMNI_BAR_PRODUCT_NAME: process.env.REACT_APP_OMNI_BAR_PRODUCT_NAME as string,
  OMNI_BAR_ENVIRONMENT: process.env.REACT_APP_OMNI_BAR_ENVIRONMENT as string,
  OMNI_BAR_PRODUCT_CODE: process.env.REACT_APP_OMNI_BAR_PRODUCT_CODE as string,
  OMNI_BAR_ENVIRONMENT_DOMAIN: process.env.REACT_APP_OMNI_BAR_ENVIRONMENT_DOMAIN as string,
  // TODO: kill these someday:
  APP_FILTERS: process.env.REACT_APP_APP_FILTERS as string,
  APP_READY_TO_PRINT: process.env.REACT_APP_APP_READY_TO_PRINT as string,
  APP_PDF_URL: process.env.REACT_APP_APP_PDF_URL as string,
  REALPAGE_LOGIN_URL: process.env.REACT_APP_REALPAGE_LOGIN_URL as string,
  AUTH_REDIRECT_URL: process.env.REACT_APP_AUTH_REDIRECT_URL as string,
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  UNIFIED_API_URL: process.env.REACT_APP_UNIFIED_API_URL as string,
  UNIFIED_SCOPE: process.env.REACT_APP_UNIFIED_SCOPE as string,
}

export interface Config {
  ENV: string
  ANALYTICS_GRAPHQL_API: string
  ANALYTICS_API: string
  ANALYTICS_WEB: string
  CRM_API: string
  CRM_WEB: string
  ADMIN_WEB: string
  SYNDICATION_AUTH_API: string
  TOKEN_ACCESS: string
  TOKEN_REFRESH: string
  TOKEN_REFRESH_HOURS: number
  SESSION_EXPIRE: number
  CALL_TIMEOUT: number
  APP_FILTERS: string
  APP_READY_TO_PRINT: string
  APP_PDF_URL: string
  REALPAGE_LOGIN_URL: string
  AUTH_REDIRECT_URL: string
  AUTH_CLIENT_ID: string
  UNIFIED_API_URL: string
  UNIFIED_SCOPE: string
  LAUNCH_DARKLY_CLIENTSIDE_ID: string
  OMNI_BAR_PRODUCT_NAME: string
  OMNI_BAR_ENVIRONMENT: string
  OMNI_BAR_PRODUCT_CODE: string
  OMNI_BAR_ENVIRONMENT_DOMAIN: string
}

export default config
