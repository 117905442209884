import React, { FC } from 'react'
import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
} from '@material-ui/core'

export interface DialogTitleProps extends MuiDialogTitleProps {}

export const DialogTitle: FC<DialogTitleProps> = ({ children, ...props }) => {
  return <MuiDialogTitle {...props}>{children}</MuiDialogTitle>
}
