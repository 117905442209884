import FeatureFlagScope from 'knock-utils/feature-flag/feature-flag-scope'

import config from '_v2/config'
import client from './apiClient'
import { client as axiosClient } from '_v2/services/api'
import { getCookie } from '_v2/services/cookies'
import {
  INITIAL_FEATURE_FLAGS_VALUE,
  featureFlagService,
  featureFlags,
  IS_ANALYTICS_DRIP_CAMPAIGNS_ENABLED,
  IS_ATTRIBUTION_ANA_ENABLED,
  IS_MULTI_TOUCH_ATTRIBUTION_ANA_ENABLED,
} from './featureFlagService'

const localStorageTokenName = config.TOKEN_ACCESS
const localStorageTokenRefreshName = config.TOKEN_REFRESH
const loginUrl = `${config.CRM_API}/auth/validate`
const userDataUrl = `${config.CRM_API}/profile`
const authLoginUiBaseUrl = `${config.SYNDICATION_AUTH_API}`
const analyticsWebBaseUrl = `${config.ANALYTICS_WEB}`
// TODO: Re-implement if needed or remove if not (KNCK-7597)
// const featureFlagUrl = `${config.ANALYTICS_API}/features`

function deleteCookies() {
  const getDomainName = () => {
    const hostname = window.location.hostname

    if (!hostname) {
      return '*'
    }

    const subdomainDots = (hostname.match(/\./g) || []).length
    if (subdomainDots >= 2) {
      const arrayOfDomainComponents = hostname.split('.')
      return `.${arrayOfDomainComponents[arrayOfDomainComponents.length - 2]}.${
        arrayOfDomainComponents[arrayOfDomainComponents.length - 1]
      }`
    }
    return hostname
  }
  document.cookie = `accessToken=;max-age=0;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${getDomainName()};`
  document.cookie = `token=;max-age=0;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${getDomainName()};`
  document.cookie = `access-token=;max-age=0;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${getDomainName()};`
}

async function login({ username, password, type = 'manager' }) {
  try {
    const res = await client({
      endpoint: loginUrl,
      body: { username, password, type },
      method: 'POST',
    })

    if (res.message === 'PASSWORD CHANGE REQUIRED') {
      window.location.href = `${config.CRM_WEB}/change-password?username=${username}&redirect=${window.location.href}`
      return
    }

    // bandaid to prevent undefined tokens
    if (res.message === 'Bad Request') {
      // clear tokens to prevent undefineds from dominating the user experience
      localStorage.clear()

      return null
    }

    deleteCookies()

    // KNCK-28699
    // The following code is commented out while development for pesistent filters is put on hold.
    // localStorage.removeItem('filters')

    const set_token = await setToken(res.token)
    await setRefreshToken(res.refresh_token)
    localStorage.setItem('username', username)

    return set_token
  } catch (error) {
    localStorage.clear()
    console.log(error)
  }
}

async function logout() {
  try {
    window.removeEventListener('storage', localStorageUpdated)
    deleteCookies()
    localStorage.clear()

    return window.location.replace(
      `${authLoginUiBaseUrl}/logout?redirect_url=${analyticsWebBaseUrl}`
    )
  } catch (error) {
    console.log(error)
  }
}

async function getUser() {
  // Short-circuiting to bypass this functionality in tests
  if (process.env.NODE_ENV === 'test') {
    return Promise.resolve({})
  }

  const token = await getToken()

  if (!token) {
    return Promise.resolve(null)
  }
  // #demo - determine if demo env
  const urlParams = new URLSearchParams(window.location.search)
  const isDemo = urlParams.get('demo')
  const hasAccessToken = !!urlParams?.get('accessToken')?.length

  if (isDemo === 'true' && hasAccessToken) {
    localStorage.setItem('sales_demo', true)
  } else if (isDemo !== 'true' && hasAccessToken) {
    localStorage.removeItem('sales_demo')
  }

  // pull sub(subject of the JWT) data out of decoded token
  const decoded_token = decodeToken(token)

  // decoded_token's sub field will contain at least the
  // following data that we care about for authentication:
  // exp, iat, iss, sub.user_id, sub.company_id, and sub.role
  //
  // if the subject's role is "agent", we can get full user profile
  // data at the user profile call below.  If the subject's role is
  // master, the user will not have a profile, preventing us from
  // gathering futher information about the user logging in.
  const parsed_token = parseDecodedToken(decoded_token)

  // instantiate user object to be modified
  let user = {
    // token data
    access_token: token,
    user_id: parsed_token.user_id,
    username: localStorage.getItem('username'),
    company_id: parsed_token.company_id,
    role: parsed_token.role,
    group_ids: parsed_token.group_ids,
  }

  // return the limited information of master account
  if (
    parsed_token.role === 'master' ||
    parsed_token.role === 'admin' ||
    parsed_token.role === 'analytics_user'
  ) {
    user = {
      // profile data
      ...user,
      is_deleted: null,
      email: null,
      first_name: null,
      last_name: null,
      manager_id: null,
      phone_number: null,
      phone_id: null,
      photo: null,
      timezone: null,
    }
  }

  // for non-master accounts, hit core's /profile endpoint to
  // retrieve data on user
  else {
    try {
      const res = await axiosClient.get(userDataUrl)
      // pull only the profile field out of the response
      const { profile } = res.data

      user = {
        // profile data
        ...user,
        is_deleted: profile.is_deleted,
        email: profile.email,
        first_name: profile.first_name,
        last_name: profile.last_name,
        manager_id: profile.manager_id,
        phone_number: profile.phone_number,
        phone_id: profile.phone_id,
        photo: profile.photo,
        timezone: profile.timezone,
      }
    } catch (error) {
      console.log(error)
    }
  }

  return user
}

const _featureFlagInitializer = async (companyId) => {
  return await featureFlagService.initialize(
    new FeatureFlagScope(companyId, {
      company: companyId,
      service: 'knock-analytics-web',
    })
  )
}

async function getFeatureFlags(companyId) {
  try {
    await _featureFlagInitializer(companyId)

    const analyticsDripCampaignsFlagValue = await featureFlagService.get(
      featureFlags.ANALYTICS_DRIP_CAMPAIGNS_ENABLED,
      false
    )

    const analyticsNewMultiTouchAttributeFlagValue = await featureFlagService.get(
      featureFlags.ATTRIBUTION_ANA_ENABLED,
      false
    )

    return {
      [IS_ANALYTICS_DRIP_CAMPAIGNS_ENABLED]: analyticsDripCampaignsFlagValue,
      [IS_ATTRIBUTION_ANA_ENABLED]: analyticsNewMultiTouchAttributeFlagValue,
      [IS_MULTI_TOUCH_ATTRIBUTION_ANA_ENABLED]: analyticsNewMultiTouchAttributeFlagValue
        ? false
        : true,
    }
  } catch {
    console.error('Error while fetching feature flags.')
    return INITIAL_FEATURE_FLAGS_VALUE
  }
}

async function getToken() {
  const urlParams = new URLSearchParams(window.location.search)

  const accessTokenFromUrl = urlParams.get('accessToken')

  if (accessTokenFromUrl) {
    deleteCookies()
  }

  const token = accessTokenFromUrl || getCookie('accessToken')

  // Add event listener for changes in token in order to log out already signed in users in other tabs
  if (token) {
    const escapedToken = token.replace(/"/g, '')
    window.removeEventListener('storage', localStorageUpdated)
    localStorage.setItem(localStorageTokenName, escapedToken)
    window.addEventListener('storage', localStorageUpdated)

    return token
  }

  const localStorageToken = window.localStorage.getItem(localStorageTokenName)
  window.addEventListener('storage', localStorageUpdated)

  // TODO: Check to see if this 'undefined' string is a mistake
  return localStorageToken === 'undefined' ? null : localStorageToken
}

export async function localStorageUpdated(event) {
  const { key, oldValue, newValue } = event

  if (key === localStorageTokenName) {
    const urlParams = new URLSearchParams(window.location.search)
    const tokenInUrl = urlParams.get('accessToken')

    if (tokenInUrl) {
      return
    }

    if (!!oldValue && newValue !== oldValue) {
      // Logout user if token was manually deleted, otherwise reload the page to re-init app
      if (!event.newValue) {
        logout()
      } else {
        window.location.reload()
      }
    }
  }

  // All values are null when localStorage.clear() is called
  if (key === null && oldValue === null && newValue === null) {
    logout()
  }
}

async function setToken(token) {
  window.localStorage.setItem(localStorageTokenName, token)
  return token
}

async function setRefreshToken(refreshToken) {
  window.localStorage.setItem(localStorageTokenRefreshName, refreshToken)
  return refreshToken
}

function decodeToken(token) {
  if (!token) return null

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

function parseDecodedToken(decoded_token) {
  if (!decoded_token) {
    return null
  }

  const { exp, iat, iss, sub } = decoded_token
  return {
    expires: exp,
    issued: iat,
    issuer: iss,
    company_id: sub.company_id,
    user_id: sub.user_id,
    role: sub.role,
    group_ids: sub.group_ids,
  }
}

export { login, logout, getToken, getUser, getFeatureFlags }
