import config from '_v4/config'

declare global {
  interface Window {
    OMNIBAR?: {
      config: {
        environment: string
        productCode: string
        productName: string
      }
    }
  }
}

export const configureOmniBar = (): void => {
  if (!window.OMNIBAR) {
    console.warn('Omnibar is not available on window. Ensure script is loaded')
  }
  window.OMNIBAR = {
    config: {
      environment: config.OMNI_BAR_ENVIRONMENT,
      productCode: config.OMNI_BAR_PRODUCT_CODE,
      productName: config.OMNI_BAR_PRODUCT_NAME,
    },
  }
}
