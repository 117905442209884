import React, { FC } from 'react'
import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
} from '@material-ui/core'

export interface DialogContentProps extends MuiDialogContentProps {}

export const DialogContent: FC<DialogContentProps> = ({ children, ...props }) => {
  return <MuiDialogContent {...props}>{children}</MuiDialogContent>
}
