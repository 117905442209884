import React from 'react'
// services
import { useUser } from '../userContext'
import { setDemoProperties } from '_v2/demo/services'
import { client } from '_v4/services/api'

import { ANALYTICS_GRAPHQL_ENDPOINTS } from '_v4/services/api/endpoints'
// types
import { IProperty, PropertyActions, PropertySelectItem, PropertyGraphQLResponse } from './types'
import GlobalDataService from 'GlobalDataService'

// instantiate contexts
const PropertyStateContext = React.createContext<State | undefined>(undefined)
const PropertyDispatchContext = React.createContext<Dispatch | undefined>(undefined)

// property state consists of:
// properties: all properties tied to the user.  this state stays static throughout session,
// selectedProperties: the filtered set of properties for the select dropdown.  slimmed down property object,
// selectedPropertyIds: object to hold all of the property ids. useful for filtering properties and sending to api
// and an error message if things go kaboom.
// singleSelectPropertyId: single property id
// toursGatedPropertyIds: ids for properties used in tours gated reports
export type State = {
  properties: IProperty[]
  knockCrmProperties: IProperty[]
  selectedProperties: PropertySelectItem[]
  selectedPropertyIds: number[]
  singleSelectPropertyId: number
  toursGatedPropertyIds: number[]
  callIntelGatedPropertyIds: number[]
  callTranscriptGatedPropertyIds: number[]
  callIntelStandalonePropertyIds: number[]
  aiVoiceGatedPropertyIds: number[]
  aiEmailGatedPropertyIds: number[]
  aiChatGatedPropertyIds: number[]
  aiSmsGatedPropertyIds: number[]
  error?: string
  isFetchingFinished: boolean
  isFetchingError: boolean
}

// propertyReducer has 3 available actions: SetAll, Filter, and PropertyError.
// SetInitial: set initial property list on app load
// SetAll: returns property state to the full list of user's properties.
// Filter: sets state.filteredProperties to a subset of state.properties based on
// the properties the user selects.
// PropertyError is used by the reducer and provider to set a property error state.
export type Action =
  | {
      type: PropertyActions.SetAll
      properties: IProperty[]
      knockCrmProperties: IProperty[]
      toursGatedPropertyIds: number[]
      callIntelGatedPropertyIds: number[]
      callTranscriptGatedPropertyIds: number[]
      aiVoiceGatedPropertyIds: number[]
      aiEmailGatedPropertyIds: number[]
      aiChatGatedPropertyIds: number[]
      aiSmsGatedPropertyIds: number[]
      callIntelStandalonePropertyIds: number[]
    }
  | {
      type: PropertyActions.Filter
      selectedPropertyIds: number[]
    }
  | {
      type: PropertyActions.PropertyError
      message: string
    }
  | {
      type: PropertyActions.SingleFilter
      singleSelectPropertyId: number
    }

export type Dispatch = (action: Action) => void

const propertyReducer = (state: State, action: Action) => {
  const { SetAll, Filter, SingleFilter, PropertyError } = PropertyActions

  switch (action.type) {
    // set all properties after initial request
    // or user selects all properties
    case SetAll: {
      // prevent overwriting the redirected-share-context by using existing selectedPropertyIds
      if (state.selectedPropertyIds.length && !state.selectedPropertyIds.includes(-1)) {
        return {
          ...state,
          properties: action.properties,
          knockCrmProperties: action.knockCrmProperties,
          toursGatedPropertyIds: action.toursGatedPropertyIds,
          callIntelGatedPropertyIds: action.callIntelGatedPropertyIds,
          callTranscriptGatedPropertyIds: action.callTranscriptGatedPropertyIds,
          callIntelStandalonePropertyIds: action.callIntelStandalonePropertyIds,
          aiVoiceGatedPropertyIds: action.aiVoiceGatedPropertyIds,
          aiEmailGatedPropertyIds: action.aiEmailGatedPropertyIds,
          aiChatGatedPropertyIds: action.aiChatGatedPropertyIds,
          aiSmsGatedPropertyIds: action.aiSmsGatedPropertyIds,
          selectedProperties: action.properties
            .filter((p: IProperty) => state.selectedPropertyIds.includes(p.id))
            .map((p) => {
              return { id: p.id, propertyName: p.propertyName }
            }),
          isFetchingFinished: true,
        }
      } else {
        return {
          ...state,
          properties: action.properties,
          knockCrmProperties: action.knockCrmProperties,
          toursGatedPropertyIds: action.toursGatedPropertyIds,
          callIntelGatedPropertyIds: action.callIntelGatedPropertyIds,
          callTranscriptGatedPropertyIds: action.callTranscriptGatedPropertyIds,
          callIntelStandalonePropertyIds: action.callIntelStandalonePropertyIds,
          aiVoiceGatedPropertyIds: action.aiVoiceGatedPropertyIds,
          aiEmailGatedPropertyIds: action.aiEmailGatedPropertyIds,
          aiChatGatedPropertyIds: action.aiChatGatedPropertyIds,
          aiSmsGatedPropertyIds: action.aiSmsGatedPropertyIds,
          selectedProperties: [{ id: -1, propertyName: 'All' }],
          selectedPropertyIds: [-1],
          isFetchingFinished: true,
        }
      }
    }
    // user selects a subset of full property list
    case Filter: {
      //TODO Update logic so when All properties are requested return list of ids, needed when all is migrated to GraphQL API
      if (action.selectedPropertyIds.includes(-1) && action.selectedPropertyIds.length === 1) {
        return {
          ...state,
          selectedProperties: [{ id: -1, propertyName: 'All' }],
          selectedPropertyIds: [-1],
        }
      } else {
        return {
          ...state,
          selectedProperties: state.properties
            .filter((p: IProperty) => action.selectedPropertyIds.includes(p.id))
            .map((p) => {
              return { id: p.id, propertyName: p.propertyName }
            }),
          selectedPropertyIds: action.selectedPropertyIds,
        }
      }
    }
    // user selects a single property id from the list
    case SingleFilter: {
      return {
        ...state,
        singleSelectPropertyId: action.singleSelectPropertyId,
      }
    }
    // set error state/flush property and selectedProperty arrays
    case PropertyError: {
      return {
        ...state,
        properties: [],
        knockCrmProperties: [],
        selectedProperties: [],
        selectedPropertyIds: [],
        message: 'Error setting properties. Properties set to empty',
        isFetchingFinished: true,
        isFetchingError: true,
      }
    }
    default: {
      throw new Error(`unhandled property action type`)
    }
  }
}

export type PropertyProviderProps = { children: React.ReactNode }
export const initialState: State = {
  properties: [],
  knockCrmProperties: [],
  selectedProperties: [{ id: -1, propertyName: 'All' }],
  selectedPropertyIds: [-1],
  singleSelectPropertyId: 0,
  toursGatedPropertyIds: [],
  callIntelGatedPropertyIds: [],
  callTranscriptGatedPropertyIds: [],
  callIntelStandalonePropertyIds: [],
  aiVoiceGatedPropertyIds: [],
  aiEmailGatedPropertyIds: [],
  aiChatGatedPropertyIds: [],
  aiSmsGatedPropertyIds: [],
  isFetchingFinished: false,
  isFetchingError: false,
}

/**
 * Property context that sets global property access based on user permissions on initial app load.
 * User's property access is determined from the bearer token in the fetch call.
 *
 * Provider data is accessed through the useProperties() custom hook.
 * @param children Components to be wrapped by the PropertyProvider.
 */
const PropertyProvider = ({ children }: PropertyProviderProps) => {
  const user = useUser()
  const { SetAll, PropertyError } = PropertyActions

  // set initial properties state to empty array of properties
  const [propertyState, propertyDispatch] = React.useReducer(propertyReducer, initialState)

  // after paint, run once to get all properties tied to a user.
  // dispatch() result to propertyReducer()
  React.useEffect(() => {
    let isMounted = true
    const fetchAllProperties = async () => {
      let allProperties: IProperty[] = []
      let knockCrmProperties: IProperty[] = []
      let toursGatedProperties: number[] = []
      let callIntelGatedProperties: number[] = []
      let callTranscriptGatedProperties: number[] = []
      let aiVoiceGatedProperties: number[] = []
      let aiEmailGatedProperties: number[] = []
      let aiChatGatedProperties: number[] = []
      let aiSmsGatedProperties: number[] = []
      let callIntelStandaloneProperties: number[] = []

      try {
        const propertiesData = GlobalDataService.properties || []

        const { GATED_PROPERTIES } = ANALYTICS_GRAPHQL_ENDPOINTS
        const REPORT_FUNC = 'getGatedProperties'
        const response = await client.post(GATED_PROPERTIES.url, {
          query: GATED_PROPERTIES.query,
        })
        toursGatedProperties = response.data.data[REPORT_FUNC].toursGatedProperties
        callIntelGatedProperties = response.data.data[REPORT_FUNC].callIntelGatedProperties
        aiVoiceGatedProperties = response.data.data[REPORT_FUNC].aiGatedProperties
        aiEmailGatedProperties = response.data.data[REPORT_FUNC].aiEmailGatedProperties
        aiChatGatedProperties = response.data.data[REPORT_FUNC].aiChatGatedProperties || []
        aiSmsGatedProperties = response.data.data[REPORT_FUNC].aiSmsGatedProperties || []
        callIntelStandaloneProperties =
          response.data.data[REPORT_FUNC].callIntelStandaloneProperties
        callTranscriptGatedProperties =
          response.data.data[REPORT_FUNC].callTranscriptGatedProperties

        const excludeStandalonePropertyIds = (propertyId: number) =>
          !callIntelStandaloneProperties.includes(propertyId)
        toursGatedProperties = toursGatedProperties.filter(excludeStandalonePropertyIds)
        aiVoiceGatedProperties = aiVoiceGatedProperties.filter(excludeStandalonePropertyIds)
        aiEmailGatedProperties = aiEmailGatedProperties.filter(excludeStandalonePropertyIds)
        aiChatGatedProperties = aiChatGatedProperties.filter(excludeStandalonePropertyIds)
        aiSmsGatedProperties = aiSmsGatedProperties.filter(excludeStandalonePropertyIds)

        if (!propertiesData || !toursGatedProperties) {
          if (isMounted) {
            return propertyDispatch({
              type: PropertyError,
              message: 'No response returned from /properties fetch. Setting properties to empty.',
            })
          }
        }

        if (propertiesData.length === 0) {
          if (isMounted) {
            return propertyDispatch({
              type: PropertyError,
              message: 'No properties found for user. Setting properties to empty.',
            })
          }
        }
        propertiesData.forEach((p: PropertyGraphQLResponse) => {
          allProperties.push({
            id: p.id,
            propertyName: p.name,
          })
        })
      } catch (error) {
        if (isMounted) {
          return propertyDispatch({
            type: PropertyError,
            message: `Exception thrown in PropertyProvider: ${error}`,
          })
        }
      }

      // #demo
      const isDemo = localStorage.getItem('demo')
      if (isDemo) {
        allProperties = await setDemoProperties(allProperties)
      }

      const sortProperties = (a: any, b: any) => {
        const nameA = a.propertyName.toLowerCase()
        const nameB = b.propertyName.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      }

      allProperties.sort(sortProperties)
      knockCrmProperties = allProperties.filter(
        (property) => !callIntelStandaloneProperties.includes(property.id)
      )

      if (isMounted) {
        propertyDispatch({
          type: SetAll,
          properties: allProperties,
          knockCrmProperties,
          toursGatedPropertyIds: toursGatedProperties,
          callIntelGatedPropertyIds: callIntelGatedProperties,
          callTranscriptGatedPropertyIds: callTranscriptGatedProperties,
          callIntelStandalonePropertyIds: callIntelStandaloneProperties,
          aiVoiceGatedPropertyIds: aiVoiceGatedProperties,
          aiEmailGatedPropertyIds: aiEmailGatedProperties,
          aiChatGatedPropertyIds: aiChatGatedProperties,
          aiSmsGatedPropertyIds: aiSmsGatedProperties,
        })
      }
    }

    fetchAllProperties()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <PropertyStateContext.Provider value={propertyState}>
      <PropertyDispatchContext.Provider value={propertyDispatch}>
        {children}
      </PropertyDispatchContext.Provider>
    </PropertyStateContext.Provider>
  )
}

// hook access to both state and dispatch contexts
const usePropertyState = () => {
  const context = React.useContext(PropertyStateContext)
  if (context === undefined) {
    throw new Error('usePropertyState must be used within a PropertyProvider')
  }
  return context
}

const usePropertyDispatch = () => {
  const context = React.useContext(PropertyDispatchContext)
  if (context === undefined) {
    throw new Error('usePropertyDispatch must be used within a PropertyProvider')
  }
  return context
}

/**
 * TODO: Single entry point to Property Context.
 *
 * @example
 * const ComponentThatNeedsPropertyData: React.FC = () => {
 *      const properties = useProperties()
 *
 * }
 */

const useProperties = () => {
  return [usePropertyState(), usePropertyDispatch()]
}

export { PropertyProvider, useProperties, usePropertyState, usePropertyDispatch }
